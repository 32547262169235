<template>
  <div class="">
    <form
      class="add-form"
      ref="profile_form"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="() => {}"
    >
      <!-- Organization -->
      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
            !hideOrganization
          "
        >
          <organization-selector
            :organization="esign.organization.id"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId) => {
                esign.organization.id = organizationId;
                onFormChanged();
              }
            "
            :disabled="!!esign.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>

      <div class="form-group-wrapper">
        <!-- Title - Name - Description - Email Message -->
         
        <!-- Esign name -->
        <div class="form-group-wrapper-item full">
          <base-input
            :label="`${$t('COMMON.NAME')} ${
              !esign.name ? '(*)' : ''
            }`"
            v-model="esign.name"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.name" />
        </div>

        
        <!-- Esign Description -->

        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('COMMON.EXCERPT')}`"
            :placeholder="`${$t('COMMON.EXCERPT')}`"
            @change="
              () => {
                onFormChanged();
              }
            "
          >
            <textarea
            v-model="esign.description"
            :placeholder="`${$t('COMMON.EXCERPT')}`"
                class="form-control"
                rows="3"
            ></textarea>
          </base-input>
          <validation-error :errors="apiValidationErrors.description" />
        </div>

        <!-- Esign Email Message -->
        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('ESIGNS.EMAIL_MESSAGE')}`"
            :placeholder="`${$t('ESIGNS.EMAIL_MESSAGE')}`"
            @change="
              () => {
                onFormChanged();
              }
            "
          >
            <textarea
            v-model="esign.email_message"
            :placeholder="`${$t('ESIGNS.EMAIL_MESSAGE')}`"
                class="form-control"
                rows="3"
            ></textarea>
          </base-input>
          <validation-error :errors="apiValidationErrors.email_message" />
        </div>


        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            :disabled="loading || processing"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
            {{
              esign.id
                ? $t("ESIGNS.EDIT_ESIGN")
                : $t("ESIGNS.ADD_ESIGN")
            }}
          </base-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select, Table, TableColumn } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import "flatpickr/dist/flatpickr.css";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [formMixin],

  props: [
    "esignData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideSubmit",
  ],

  data() {
    let esignData = { ...this.esignData };
    esignData = this.$fillUserOrganizationData(esignData);
    return {
      esign: esignData,
      esigns: [],
      total: 0,
      processing: false,
    };
  },

  methods: {
    async handleSubmit() {
      const checkUniqueEsign = await this.checkEsignUniqueInformation();
      if (checkUniqueEsign) {
        await this.sendInformation();
      } else {
        this.showEsignCheckModal = true;
      }
    },

    async sendInformation() {
      let esignData = cloneDeep(this.esign);
      esignData = this.$fillUserOrganizationData(esignData);
      this.$emit("esignSubmitted", esignData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      if (!this.esign?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.esign?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
          include: "organization",
        };

        if (this.esign?.organization?.id) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.esign?.organization?.id,
            },
          };
        }
        await this.$store.dispatch("esigns/list", params);
        this.esigns = this.$store.getters["esigns/list"];
        this.total = this.$store.getters["esigns/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkEsignUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.esign?.id;
        default:
          return false;
      }
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    esignData(esignData) {
      if (esignData) {
        this.esign = {
          ...this.esign,
          ...cloneDeep(esignData),
        };
      }
    },
  },
};
</script>
