<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("ESIGNS.ESIGNS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openEsignCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_ESIGNS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("ESIGNS.ADD_ESIGN")
              }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_ESIGNS)"
              :objectType="'esigns'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <esign-list-table
        @onViewEsign="openEsignViewModal"
        @onEditEsign="openEsignEditModal"
        @onDeleteEsign="deleteEsign"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewEsignModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewEsignModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content w-100"
          v-resizable-modal="'ESIGN_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ESIGNS.VIEW_ESIGN") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openEsign"
                :objectType="'esigns'"
                :objectId="openEsign.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
               v-if="openEsign.status==STATUS_DRAFT"
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openEsignEditModal(openEsign)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="delete" @click="deleteEsign(openEsign)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeEsignModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-esign-component
              v-if="openEsign"
              :esignId="openEsign.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditEsignModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditEsignModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'ESIGN'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ESIGNS.EDIT_ESIGN") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openEsignViewModal(openEsign)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeEsignModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-esign-component
              v-if="openEsign"
              :esignId="openEsign.id"
              @onViewEsign="openEsignViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddEsignModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddEsignModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'ESIGN'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ESIGNS.ADD_ESIGN") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeEsignModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-esign-component @onViewEsign="openEsignViewModal" />
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT_ESIGN_TRANSACTION,
  STATUS_DRAFT
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import EsignListTable from "./partials/EsignListTable.vue";
import EditEsignComponent from "./components/EditEsignComponent.vue";
import AddEsignComponent from "./components/AddEsignComponent.vue";
import ViewEsignComponent from "./components/ViewEsignComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    EsignListTable,
    NotificationSubscription,
    EditEsignComponent,
    AddEsignComponent,
    ViewEsignComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const esignId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewEsignModalOpened = false;
    let isEditEsignModalOpened = false;
    let isAddEsignModalOpened = false;
    let openEsign = null;
    if (esignId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewEsignModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditEsignModalOpened = true;
      }
      openEsign = { id: esignId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddEsignModalOpened = true;
    }
    return {
      isViewEsignModalOpened: isViewEsignModalOpened,
      isEditEsignModalOpened: isEditEsignModalOpened,
      isAddEsignModalOpened: isAddEsignModalOpened,
      openEsign: openEsign,
      renderKey: 1,
      STATUS_DRAFT:STATUS_DRAFT
    };
  },

  methods: {
    openEsignCreateModal() {
      this.closeEsignModal();
      this.isAddEsignModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListEsignsPackages",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openEsignViewModal(esign, reRender = false) {
      this.closeEsignModal();
      this.openEsign = esign;
      this.isViewEsignModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListEsignsPackages",
          query: { id: this.openEsign.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openEsignEditModal(esign) {
      this.closeEsignModal();
      this.openEsign = esign;
      this.isEditEsignModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListEsignsPackages",
          query: { id: this.openEsign.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeEsignModal() {
      this.isAddEsignModalOpened = false;
      this.isEditEsignModalOpened = false;
      if(this.isViewEsignModalOpened){
        this.isViewEsignModalOpened = false;
        this.renderKey++;
      }
      this.openEsign = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListEsignsPackages",
          query: {},
        }).href
      );
    },

    async deleteEsign(esign) {
      const confirmation = await swal.fire({
        title: this.$t("ESIGNS.DELETE_THIS_ESIGN"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("esigns/destroy", esign.id);
          this.renderKey++;
          this.closeEsignModal();
          this.$notify({
            type: "success",
            message: this.$t("ESIGNS.ESIGN_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
