<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <organization-selector
          v-if="
            !filterOrganization &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          @organizationChanged="
            (organizationId) => (selectedOrganization = organizationId)
          "
        />
        <base-input>
                <el-select
                  :label="$t('COMMON.STATUS')"
                  :placeholder="$t('COMMON.STATUS')"
                  v-model="selectedStatut"
                  @change="
                    (status) => {
                      selectedStatut = status;
                    }
                  "
                >
                  <el-option
                    v-for="(value, key) in status_list"
                    :key="key"
                    :value="value"
                    :label="value?$t(`ESIGNS.${value}`):''"
                  />
                </el-select>
              </base-input>
        <base-input
          v-model="query"
          type="search"
          prepend-icon="fas fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
        <base-button
          class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm mb-4"
          icon
          size="sm"
          @click="resetFilters"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-undo"></i>
          </span>
        </base-button>
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="esigns"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column
              :label="$t('COMMON.NAME')"
              prop="name"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.name ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.STATUS')"
              prop="status"
              min-width="220"
            >
              <template v-slot="{ row }">
                <status-badge :status="row.status" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.ORGANIZATION')"
              v-if="
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
              "
              min-width="200"
            >
              <template v-slot="{ row }">
                <organization :organization="row.organization" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ESIGNS)"
                >
                  <a
                    type="text"
                    @click="viewEsign(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="row.status === STATUS_DRAFT ? $t('COMMON.EDIT') : $t('ESIGNS.CANNOT_EDIT')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_ESIGNS)"
                >
                  <a
                    type="text"
                    @click="row.status === STATUS_DRAFT && editEsign(row)"
                    :class="['table-action', 'table-action-edit', { 'disabled-link': row.status !== STATUS_DRAFT }]"
                    :style="{'cursor': row.status === STATUS_DRAFT ? 'pointer' : 'not-allowed'}"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="row.status === STATUS_DRAFT ? $t('COMMON.DELETE') : $t('ESIGNS.CANNOT_DELETE')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_DELETE_ESIGNS)"
                >
                  <a
                    type="text"
                    @click="row.status === STATUS_DRAFT && deleteEsign(row.id)"
                    :class="['table-action', 'table-action-delete', { 'disabled-link': row.status !== STATUS_DRAFT }]"
                    :style="{'cursor': row.status === STATUS_DRAFT ? 'pointer' : 'not-allowed'}"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { STATUS_LIST, STATUS_DRAFT } from "@/constants/common";
import StatusBadge from "../components/StatusBadge.vue";

export default {
  name: "esign-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    StatusBadge,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    }
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      esigns: [],
      loading: true,
      selectedOrganization: null,
      selectedStatut: null,
      status_list:STATUS_LIST,
      STATUS_DRAFT:STATUS_DRAFT
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedStatut: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    resetFilters() {
      this.selectedOrganization = null;
      this.query = null;
      this.selectedStatut = null;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.selectedStatut) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatut,
            },
          };
        }

        await this.$store.dispatch("esigns/list", params);
        this.esigns = this.$store.getters["esigns/list"];
        this.total = this.$store.getters["esigns/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editEsign(customer) {
      this.$emit("onEditEsign", customer);
      /* this.$router.push({
        name: "Edit Customer",
        params: { id: row.id },
      }); */
    },

    async deleteEsign(id) {
      const confirmation = await swal.fire({
        title: this.$t("ESIGNS.DELETE_THIS_ESIGN"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("esigns/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("ESIGNS.ESIGN_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewEsign(esign) {
      this.$emit("onViewEsign", esign);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
