var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"form-wrapper full"},[(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
          !_vm.hideOrganization
        )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.esign.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.esign.id},on:{"organizationChanged":(organizationId) => {
              _vm.esign.organization.id = organizationId;
              _vm.onFormChanged();
            }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1),_c('div',{staticClass:"form-group-wrapper"},[_c('div',{staticClass:"form-group-wrapper-item full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} ${
            !_vm.esign.name ? '(*)' : ''
          }`},on:{"change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.esign.name),callback:function ($$v) {_vm.$set(_vm.esign, "name", $$v)},expression:"esign.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1),_c('div',{staticClass:"form-group-wrapper-item"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXCERPT')}`,"placeholder":`${_vm.$t('COMMON.EXCERPT')}`},on:{"change":() => {
              _vm.onFormChanged();
            }}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.esign.description),expression:"esign.description"}],staticClass:"form-control",attrs:{"placeholder":`${_vm.$t('COMMON.EXCERPT')}`,"rows":"3"},domProps:{"value":(_vm.esign.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.esign, "description", $event.target.value)}}})]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.description}})],1),_c('div',{staticClass:"form-group-wrapper-item"},[_c('base-input',{attrs:{"label":`${_vm.$t('ESIGNS.EMAIL_MESSAGE')}`,"placeholder":`${_vm.$t('ESIGNS.EMAIL_MESSAGE')}`},on:{"change":() => {
              _vm.onFormChanged();
            }}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.esign.email_message),expression:"esign.email_message"}],staticClass:"form-control",attrs:{"placeholder":`${_vm.$t('ESIGNS.EMAIL_MESSAGE')}`,"rows":"3"},domProps:{"value":(_vm.esign.email_message)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.esign, "email_message", $event.target.value)}}})]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email_message}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading || _vm.processing}},[(_vm.loading || _vm.processing)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.esign.id ? _vm.$t("ESIGNS.EDIT_ESIGN") : _vm.$t("ESIGNS.ADD_ESIGN"))+" ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }